import { FormSchema } from "../../components";
import { SmartValueDetails, Submission } from "../../types";
import { fetchJson, getApiFullUrl, postApi, uploadApi, Base64File, AldoError, AldoErrorCode } from "../../utils";
import { getOriginalFile } from "../../utils/base64-file";

export const saveCheckoutDetails = async (submissionType: string, checkoutDetails: {
    userId: string,
    orgId: string,
    formValues: any,
    submissionId: string,
    formVersion: string,
    formUrl: string,
    formTitle:string,
    name: string
}, reCaptchaToken: string): Promise<void> => {
    await postApi<Submission, null>(getApiFullUrl(`/submit/${submissionType}`), checkoutDetails, reCaptchaToken);
}

export const uploadFile = async (checkoutId: string, fieldName: string, file: File, getReCaptchaToken: () => Promise<string>, onUploadProgress: (loaded: number) => void): Promise<string> => {
    const key = `${checkoutId}/${fieldName}/${file.name}`;
    const putUrl = await fetchJson<{ url: string }>(getApiFullUrl(`/attachment-upload-url?path=${key}&mimeType=${file.type}`), await getReCaptchaToken());
    await uploadApi(putUrl.url, file, onUploadProgress);
    return key;
}

export const uploadFiles = async (checkoutId: string, fieldName: string, files: File[] | Base64File[], getReCaptchaToken: () => Promise<string>, onUploadProgress: (loaded: number) => void): Promise<string[]> => {
    const keys: string[] = [];
    for (const file of files) {
        const uploadingFile = await getOriginalFile(file);
        keys.push(await uploadFile(checkoutId, fieldName, uploadingFile, getReCaptchaToken, onUploadProgress));
    }
    return keys;
}

export const getSchema = async (schemaUrl: string, sessionId: string | undefined, userId: string | undefined, action: string | undefined,assetId:string|undefined, getReCaptchaToken: () => Promise<string>,): Promise<FormSchema> => {
    const userIdParam = userId ? `&userId=${userId}` : '';
    const sessionIdParam = sessionId ? `&sessionId=${sessionId}` : '';
    const actionParam = action ? `&action=${action}` : '';
    const assetIdParam = assetId ? `&assetId=${assetId}` : '';
    const data = await fetchJson<{ url: string, error?: string }>(getApiFullUrl(`/schema-download-url?path=${schemaUrl}${sessionIdParam}${userIdParam}${actionParam}${assetIdParam}`), await getReCaptchaToken());
    if (data.error) {
        throw new AldoError(AldoErrorCode.InvalidFormRequest, data.error);
    }
    return await fetchJson<FormSchema>(data.url, await getReCaptchaToken());
}

export const getSmartValue = async (orgId: string, userId: string, smartValueId: string, reCaptchaToken: string): Promise<SmartValueDetails> => {
    type ReqBody = {
        orgId: string,
        userId: string,
        smartValueId: string
    }
    const data = await postApi<ReqBody, SmartValueDetails & { error?: string }>(getApiFullUrl(`/smart-value-details`), {
        orgId: orgId,
        userId: userId,
        smartValueId: smartValueId
    }, reCaptchaToken);
    if (data.error) {
        throw new AldoError(AldoErrorCode.InvalidSmartQR, data.error);
    }
    return data;
}