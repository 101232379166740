export enum AldoErrorCode {
    InvalidFormRequest,
    InvalidSmartQR
}

export class AldoError extends Error {
    code: AldoErrorCode;
    constructor(code: AldoErrorCode, message: string) {
        super(message);
        this.code = code;
    }
}
